<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical">
        <c-tab
          :dense="true"
          type="pagetop"
          :tabItems.sync="tabItems"
          :inlineLabel="true"
          v-model="tab"
          align="left"
        >
          <template v-slot:default="tab">
            <component
              :is="tab.component"
            />
          </template>
        </c-tab>
      </div>
    </div>
  </div>
</template>
<script>
// import selectConfig from '@/js/selectConfig';
// import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'air-sems-manage',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          title: '',
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      splitter: { data: 220 },
      tab: 'tab1',
      tabItems: [
        { name: 'tab1', label: '가동시간', component: () => import(`${'./airSems01.vue'}`) },
        { name: 'tab2', label: '보수사항', component: () => import(`${'./airSems02.vue'}`) },
        { name: 'tab3', label: '시설운전사항', component: () => import(`${'./airSems03.vue'}`) },
        { name: 'tab4', label: '연료사용량', component: () => import(`${'./airSems04.vue'}`) },
        { name: 'tab5', label: '원료사용량', component: () => import(`${'./airSems05.vue'}`) },
      ],
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 220);
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getData();
    },
    getData() {
    },
  }
};
</script>